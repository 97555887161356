<template>
	<template v-for="panel in panels" :key="panel.key">
		<div class="screen-backdrop"></div>
		<div class="modal" tabindex="-1" style="display: block; overflow-y: auto;">
			<div class="modal-dialog" :class="Object.assign(panel.dialogClass, { 'modal-dialog-scrollable': panel.scrollable })" :style="panel.dialogStyle">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">{{ panel.caption }}</h5>
						<button type="button" class="close" @click.prevent="closePanel(panel)" v-if="panel.closeable">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body" :style="panel.bodyStyle">
						<component :is="panel.component" :ref="`panel_${panel.key}`" :params="panel.params"></component>
					</div>
					<div class="modal-footer">
						<PanelFun :items="panel.buttons"></PanelFun>
					</div>
				</div>
			</div>
		</div>
	</template>
</template>

<script>
import { computed } from 'vue';

import { panels, closePanel } from "@/core/layouts";

export default {
	updated() {
		//Установка ссылки на компонент панели
		setTimeout(() => {
			for (const panel of panels.value) {
				if (this.$refs[`panel_${panel.key}`] && !panel.ref) {
					panel.ref = this.$refs[`panel_${panel.key}`][0];

					panel.onShow(panel);
				}
			}
		}, 100);
	},

	setup() {
		return {
			panels: computed(() => panels.value.filter(panel => panel.modal)),
			closePanel
		}
	}
}

</script>
