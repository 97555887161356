<template>
	<div class="lds-container" :class="{ showloader: load }">
		<div class="lds-dual-ring"></div>
	</div>

	<component :is="layout">
		<router-view v-slot="{ Component }">
			<component :is="Component" />
		</router-view>
	</component>

	<TimeOut v-if="timeout"></TimeOut>
	<Modals></Modals>
	<ContextMenu></ContextMenu>
</template>

<script>
import { computed, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

import Modals from "@/layouts/Modals.vue";
import EmptyLayout from "@/layouts/Empty.vue";
import DefaultLayout from "@/layouts/Default.vue";

import ContextMenu from "@/core/components/ContextMenu/index.vue";
import { loadTables } from '@/core/db';

import TimeOut from "@/pages/TimeOut.vue";

import tables from '@/tables';

export default {
	components: {
		Modals,
		TimeOut,
		ContextMenu,
		EmptyLayout,
		DefaultLayout
	},

	setup() {
		const route = useRoute();
		const { state, commit } = useStore();

		const handeInterval = setInterval(() => {
			if (state?.user?.exp && state.timeout == 0) {
				const date = new Date;

				const timeout = ~~((state.user.exp - date.getTime()) / 1000);

				if (timeout < 180) commit('timeout', timeout);
			}
		}, 5000);

		loadTables(tables);

		onUnmounted(() => clearTimeout(handeInterval));

		return {
			load: computed(() => state.load),
			timeout: computed(() => state.timeout),
			layout: computed(() => route.meta.layout || "default-layout")
		}
	}
}
</script>

<style scoped>
.lds-container {
	display: flex;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	z-index: 99999 !important;
	background-color: rgba(0, 0, 0, 0.1);

	visibility: hidden;
	opacity: 0;
	transition: 0.1s;
}

.lds-container.showloader {
	visibility: visible;
	opacity: 1;
}

.lds-dual-ring {
	display: inline-block;
	width: 80px;
	height: 80px;
}

.lds-dual-ring:after {
	content: " ";
	display: block;
	width: 64px;
	height: 64px;
	margin: 8px;
	border-radius: 50%;
	border: 6px solid #337ab7;
	border-color: #337ab7 transparent #337ab7 transparent;
	animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
</style>
