<template>
	<div class="sidebar sidebar-dark sidebar-main sidebar-expand-md" :class="{ 'sidebar-fullscreen': sidebarFullscreen }">
		<div class="sidebar-mobile-toggler text-center">
			<a href="#" class="sidebar-mobile-main-toggle" @click="closeSidebar">
				<i class="icon icon-chevron-left"></i>
			</a>
			Главное меню
			<a href="#" class="sidebar-mobile-expand" @click="onChangeSidebarFullscreen">
				<i class="icon icon-maximize"></i>
				<i class="icon icon-minimize"></i>
			</a>
		</div>

		<div class="sidebar-content">
			<div class="card card-sidebar-mobile">
				<ItemsSidebar :items="menuItems" ref="sidebar" />
			</div>
		</div>
	</div>
</template>

<script>
import { onMounted, onUnmounted, ref } from "vue";
import { closeSidebar, sidebarFullscreen } from "@/core/layouts";

import ItemsSidebar from "./ItemsSidebar";
import { getMenu } from "@/api/profile";
import { config } from '@/config';
import bus from "@/core/bus";

export default {
	components: {
		ItemsSidebar
	},

	setup() {
		const menuItems = ref([]);

		const fetchMainMenu = async () => {
			const { menu = '' } = config;

			menuItems.value = await getMenu(menu);
		}

		onMounted(async () => {
			await fetchMainMenu();

			bus.$on('login', fetchMainMenu);
			bus.$on('logout', fetchMainMenu);
		})

		onUnmounted(() => {
			bus.$off('login', fetchMainMenu);
			bus.$off('logout', fetchMainMenu);
		})

		return {
			menuItems,
			sidebarFullscreen,
			closeSidebar,
			onChangeSidebarFullscreen: () => sidebarFullscreen.value = !sidebarFullscreen.value
		}
	}
};
</script>

<style></style>
