import api from '@/core/api/api'

export default {
    token: api.token.get(),
    user: {
        id: null,
        roles: [],
        name: null,
        employee: null,
        _employee: null,
        organization: null,
        department: null,
        avatar: null
    },
    timeout: 0,
    messages: [],
    equipment: [],
    online: false,
    onlineAgent: false,
    mute: true,
    load: false,
    locale: 'ru',
    loaded: false,
    openDocument: true,
    constants: {
        version: '0.0.0',
        files: {
            maxSize: 0
        },
        license: '',
        jitsiMeet: {
            domain: ''
        },
        accounting: {
            print_card: false,
            print_blank: false,
            protocol_onmk: false,
            edit_disp: false
        }
    },
    channels: [],
    cbPhoneNumber: ''
}