import { createRouter, createWebHistory } from "vue-router";

import stateStore from "@/store"
import { breadCrumb, closeAllPanels, pageTitle, panels, setCaption } from "@/core/layouts";

const routes = [
	{
		path: '',
		component: () => import('@/layouts/Panels.vue'),
		children: [
			{
				path: '',
				component: () => import('@/pages/main/index.vue')
			},
			{
				path: '/map',
				component: () => import('@/pages/LeafletMap.vue'),
				meta: {
					title: 'Карта'
				}
			},
			{
				path: '/webinars',
				component: () => import('@/tables/webinars/List/index.vue'),
				meta: {
					roles: ['admin']
				}
			},
			{
				path: '/situation',
				component: () => import('@/pages/situation/index.vue')
			},
			{
				path: '/reports',
				component: () => import('@/reports/index.vue')
			},
			{
				path: '/health_statement',
				component: () => import('@/tables/health_statement/forms/List.vue')
			},
			{
				path: '/publications/:guid',
				component: () => import('@/pages/Publication.vue'),
				meta: {
					title: ''
				}
			},
			{
				path: '/orders',
				component: () => import('@/tables/orders/forms/List.vue'),
				meta: {
					roles: ['admin', 'dispatcher', 'auditor', 'shifts']
				}
			},
			{
				path: '/dispatcher',
				component: () => import('@/tables/call_log/Dispatcher/index.vue'),
				meta: {
					roles: ['admin', 'dispatcher', 'auditor']
				}
			},
			{
				path: '/call_log',
				component: () => import('@/tables/call_log/List/index.vue'),
				meta: {
					roles: ['admin', 'dispatcher', 'auditor', 'print-card']
				}
			},
			{
				path: '/call_log/:id',
				component: () => import('@/tables/call_log/List/index.vue'),
				meta: {
					roles: ['admin', 'dispatcher', 'auditor', 'print-card']
				}
			},
			{
				path: '/assessment',
				component: () => import('@/tables/call_log/Assessment/index.vue'),
				meta: {
					roles: ['admin', 'assess-senior', 'assess-manager', 'assess-sub']
				}
			},
			{
				path: '/assessment',
				component: () => import('@/tables/call_log/Assessment/index.vue'),
				meta: {
					roles: ['admin', 'assess-senior', 'assess-manager', 'assess-sub']
				}
			},
			{
				path: '/patients',
				component: () => import('@/tables/patients/forms/List.vue'),
				meta: {
					roles: ['admin', 'patients', 'auditor']
				}
			},
			{
				path: '/patients/:id',
				component: () => import('@/tables/patients/forms/List.vue'),
				meta: {
					roles: ['admin', 'patients', 'auditor']
				}
			},
			{
				path: '/inspection',
				component: () => import('@/pages/inspection/index.vue'),
				meta: {
					roles: ['admin', 'statistics', 'manager', 'dispatcher']
				}
			},
			{
				path: '/inspection_patients',
				component: () => import('@/pages/inspection_patients/index.vue'),
				meta: {
					roles: ['admin', 'statistics', 'manager', 'dispatcher']
				}
			},
			{
				path: '/tables',
				component: () => import('@/pages/ViewTables.vue'),
				meta: {
					title: 'Список таблиц',
					roles: ['admin']
				}
			},
			{
				path: '/tables/:table',
				component: () => import('@/core/components/DB/List.vue'),
				meta: {
					title: 'Таблица'
				}
			},
			{
				path: '/messages',
				component: () => import('@/pages/messages.vue'),
				meta: {
					title: 'Сообщения'
				}
			}
		]
	},
	{
		path: '/profile',
		component: () => import('@/pages/profile/index.vue'),
		meta: {
			title: 'Профиль',
			breadCrumb: [
				{
					route: '/profile',
					icon: 'icon icon-user',
					caption: 'Профиль'
				}
			]
		},
		children: [
			{
				path: '',
				component: () => import('@/pages/profile/page.vue'),
				meta: {
					title: 'Основные данные',
					icon: 'icon icon-more-horizontal',
					breadCrumb: [
						{
							route: '/profile',
							icon: 'icon icon-user',
							caption: 'Профиль'
						},
						{
							icon: 'icon icon-book-open',
							caption: 'Основные данные'
						}
					]
				}
			},
			{
				path: 'page',
				component: () => import('@/pages/profile/page.vue'),
				meta: {
					title: 'Основные данные',
					icon: 'icon icon-book-open',
					breadCrumb: [
						{
							route: '/profile',
							icon: 'icon icon-user',
							caption: 'Профиль'
						},
						{
							icon: 'icon icon-book-open',
							caption: 'Основные данные'
						}
					]
				}
			},
			{
				path: 'equipment',
				component: () => import('@/pages/profile/equipment.vue'),
				meta: {
					title: 'Оборудование',
					icon: 'icon icon-printer',
					breadCrumb: [
						{
							route: '/profile',
							icon: 'icon icon-user',
							caption: 'Профиль'
						},
						{
							icon: 'icon icon-printer',
							caption: 'Оборудование'
						}
					]
				}
			}
		]
	},
	{
		path: '/user/:guid',
		component: () => import('@/pages/UserProfile/index.vue'),
		meta: {
			title: 'Профиль'
		},
		children: [
			{
				path: '',
				component: () => import('@/pages/UserProfile/AboutUser.vue'),
				meta: {
					title: 'О пользователе',
					icon: 'icon-user'
				}
			},
			{
				path: 'aboutuser',
				component: () => import('@/pages/UserProfile/AboutUser.vue'),
				meta: {
					title: 'О пользователе',
					icon: 'icon-user'
				}
			}
		]
	},
	{
		path: '/login',
		component: () => import('@/pages/auth/Login.vue'),
		meta: {
			layout: 'empty-layout'
		}
	},
	{
		path: '/404',
		component: () => import('@/pages/Page404.vue')
	},
	{
		path: '/:pathMatch(.*)',
		component: () => import('@/pages/Page404.vue')
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

router.beforeEach(async (to, from) => {
	//Прописывать в роутах роли и проверять доступ при переходах
	if (to.fullPath != from.fullPath) {
		for (const panel of panels.value) {
			if (panel.onBeforeClose) {
				if (!await panel.onBeforeClose()) return false;
			}
		}

		closeAllPanels();

		setCaption('');
	}

	//Получаем статус пользователя
	const user: any = stateStore.state.user.id ? stateStore.state.user : await stateStore.dispatch('status');

	//Если в метаданных прописаны роли, то производим проверку на доступ, если не указана ни одна роль - доступ запрещен
	if (to.meta.roles) {
		const roles: any = to.meta.roles;

		for (const routeRole of roles) {
			if (user.roles.includes(routeRole)) return true;
		}

		return { path: '/404' };
	} else {
		return true;
	}
})

router.afterEach((to) => {
	breadCrumb.value = to.meta.breadCrumb ? to.meta.breadCrumb : [];

	pageTitle.value = to.meta.title ? to.meta.title : null;
})

export default router;

export const openRoute = (path: string) => {
	const currentRoute = router.currentRoute.value.path.split('/').filter(el => el);
	const newRoute = path.split('/').filter(el => el);

	if (currentRoute.length > 0 && newRoute.length > 0 && currentRoute[0] == newRoute[0]) {
		router.replace(path);
	}
}
